@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.alert-message {
	@extend %text-bold;
	margin-bottom: 10px;
	padding: 10px;
	background: lighten($color-info, 32%);
	color: $color-info;
	animation: alphaBounce 1s ease;
	animation-iteration-count: 1;
	transform-origin: 50% 50%;

	&__message {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		+ .list {
			margin-top: 10px;
		}
	}

	&__text {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		white-space: pre-line;
	}

	.amount {
		display: inline-block;
		color: $color-info;
	}

	.relative-link {
		@extend %text-bold;
		display: inline-block;
		text-decoration: underline;
		color: $color-info;
	}

	.icon,
	svg {
		min-width: 20px;
		height: 20px;
		width: 20px;
		margin-right: 10px;
	}

	.list {
		&__item {
			margin-bottom: 2px;
			color: $color-info;
			font-weight: normal;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&--error {
		background: lighten($color-error, 50%);
		color: $color-error;

		.amount {
			color: $color-error;
		}

		.relative-link {
			color: $color-error;
		}

		.list__item {
			color: $color-error;
		}
	}

	&--success {
		background: lighten($color-valid, 50%);
		color: $color-valid;

		.amount {
			color: $color-valid;
		}

		.relative-link {
			color: $color-valid;
		}

		.list__item {
			color: $color-valid;
		}
	}

	@include gridle_state(md) {
		padding-right: 20px;
		padding-left: 20px;
	}
}
